<template>
  <q-form ref="editForm">
    <!-- 실시 진행사항 -->
    <c-card title="LBL0001974" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 완료 -->
          <c-btn 
            v-show="editable&&!disabled&&!completeCheck" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="relatedWork"
            mappingType="PUT"
            label="LBLCOMPLETE" 
            icon="check"
            @beforeAction="completeRelatedWork"
            @btnCallback="completeRelatedWorkCallback" />
          <!-- 저장 -->
          <c-btn 
            v-if="editable&&!disabled&&!completeCheck" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="relatedWork"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveRelatedWork"
            @btnCallback="saveRelatedWorkCallback"/>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- :disabled="!emergencyCheck||disabled||completeCheck" -->
            <!-- 완료예정일 -->
          <c-datepicker
            :required="true"
            :editable="editable"
            :disabled="disabled||completeCheck"
            label="LBL0001967"
            name="actionScheduleCompleteDate"
            v-model="relatedWork.actionScheduleCompleteDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <template v-if="!emergencyCheck">
            <!-- 관리자 -->
            <c-field 
              :editable="editable"
              :disabled="disabled||completeCheck"
              :data="relatedWork"
              deptValue="actionDeptCd"
              type="dept_user" 
              label="LBLMANAGER" 
              name="actionUserId" 
              v-model="relatedWork.actionUserId" />
              <!-- :disabled="true" -->
            <!-- <c-text 
              :editable="editable"
              :disabled="disabled||completeCheck"
              label="LBLMANAGER" 
              name="actionInfo"
              v-model="actionInfo">
            </c-text> -->
          </template>
          <template v-else>
            <!-- 관리자 -->
            <c-field 
              :required="true" 
              :editable="editable"
              :disabled="disabled||completeCheck"
              :data="relatedWork"
              deptValue="actionDeptCd"
              type="dept_user" 
              label="LBLMANAGER" 
              name="actionUserId" 
              v-model="relatedWork.actionUserId" />
          </template>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- :disabled="true" -->
            <!-- 완료일 -->
          <c-text 
            :editable="editable"
            :disabled="disabled||completeCheck"
            label="LBL0001975" 
            name="actionCompleteDate"
            v-model="relatedWork.actionCompleteDate">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- :disabled="!emergencyCheck||disabled||completeCheck" -->
            <!-- 확인자 -->
          <c-field 
            :required="true"
            :editable="editable"
            :disabled="disabled||completeCheck"
            :data="moc"
            deptValue="checkDeptCd"
            type="dept_user" 
            label="LBL0001950" 
            name="checkUserId" 
            v-model="relatedWork.checkUserId" />
        </div>
        <div class="col-12">
          <!-- 확인내용 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled||completeCheck"
            :rows="3"
            label="LBL0001976" 
            name="checkContents"
            v-model="relatedWork.checkContents">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <!-- 관련 문서 -->
    <c-table
      ref="table"
      class="q-mt-sm"
      title="LBL0001977"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="relatedWork.relatedWorkDocuments"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled&&!completeCheck"
      selection="multiple"
      rowKey="sopChangeRelatedWorkDocumentId"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable&&!disabled&&!completeCheck" label="LBLADD" icon="add" @btnClicked="addDocument" />
          <!-- 제외 -->
          <c-btn v-if="editable&&!disabled&&!completeCheck" label="LBLEXCEPT" icon="remove" @btnClicked="removeDocument" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'task-proceeding',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
      }),
    },
    relatedWork: {
      type: Object,
      default: () => ({
        sopChangeRelatedWorkId: '',  // 관련업무 일련번호
        sopMocId: '',  // MOC 일련번호
        mocRelatedTaskCd: '',  // 관련업무 코드
        changeFlag: '',  // 변경/실행 여부
        actionDeptCd: '',  // 실행 담당 부서코드
        actionDeptName: '',  // 실행 담당 부서명
        actionUserId: '',  // 실행 담당자 ID
        actionUserName: '',  // 실행 담당자 명
        actionScheduleCompleteDate: '',  // 실행 완료예정일
        actionCompleteDate: '',  // 실행 완료일
        checkUserId: '',  // 확인자 ID
        checkUserName: '',  // 확인자 명
        checkDate: '',  // 확인일
        checkContents: '',  // 확인내용
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        relatedWorkDocuments: [],
        deleteRelatedWorkDocuments: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'documentTitle',
            field: 'documentTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'documentRegUserName',
            field: 'documentRegUserName',
            // 등록자
            label: 'LBL0001978',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'documentRegDt',
            field: 'documentRegDt',
            // 등록일
            label: 'LBL0001979',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        height: '400px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    actionInfo() {
      return this.relatedWork.actionDeptName + ' / ' + this.relatedWork.actionUserName
    },
    completeCheck() {
      return this.relatedWork.completeFlag === 'Y'
    },
    emergencyCheck() {
      return this.moc.mocTypeCd === 'MT00000010'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.moc.relatedWork.singleSave.url
      this.completeUrl = transactionConfig.sop.moc.relatedWork.complete.url
      // code setting
      // list setting
      this.setColumns();
    },
    setColumns() {
      let columns = [];
      switch(this.relatedWork.mocRelatedTaskCd) {
        case 'RT00000001': // 공정안전자료 업데이트
          /**
           * 분류 : 공정안전자료 분류 정보
           *  ex) 동력기계, 장치 및 설비, 공정흐름도, 소화설비 설치도 등등
           * 제목 : 도면의 경우 도면명
           *       시설정보의 경우 시설명
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              // 분류
              label: 'LBLCLASSIFICATION',
              align: 'center',
              style: 'width:20%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              // 제목
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:50%',
              sortable: false,
              type: 'link',
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'revisionNum',
              field: 'revisionNum',
              // 개정번호
              label: 'LBLREVNO',
              align: 'center',
              style: 'width:80px',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 제/개정일
              label: 'LBL0001980',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000005': // 위험성평가 결과
          /**
           * 분류 : 위험성평가 종류
           *  ex) HAZOP, K-PSR, KRAS, JSA, Check-list
           * 제목 : 평가명
           * 진행상태 : 위험성평가 진행상태명
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              // 분류
              label: 'LBLCLASSIFICATION',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              // 제목
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:40%',
              sortable: false,
              type: 'link',
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              // 진행단계
              label: 'LBLPROGRESS',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 등록일
              label: 'LBL0001979',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000010': // 교육 결과
          /**
           * 분류 : 교육과정명
           *  ex) PSM 관련교육, 관리감독자 교육 등등
           * 제목 : 교육명
           * 진행상태 : 교육계획 진행상태명
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              // 분류
              label: 'LBLCLASSIFICATION',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              // 제목
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:40%',
              sortable: false,
              type: 'link',
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              // 진행상태
              label: 'LBLPROGRESS',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 등록일
              label: 'LBL0001979',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000015': // 가동전 점검 결과
          /**
           * 제목 : 점검명
           * 진행상태 : 가동전점검 진행상태명
           * 개선진행수 : 
           *  1. 진행중인 건 수 : 개선 완료되지 않은 개선 건 수
           *  2. 완료 된 건 수 : 개선 완료된 건 수  + 즉시조치 건 수
           *  3. 총 건 수 : 개선 건 수
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'documentTitle',
              field: 'documentTitle',
              // 제목
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:40%',
              sortable: false,
              type: 'link',
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              // 진행단계
              label: 'LBLPROGRESS',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'impr',
              field: 'impr',
              // 개선진행
              label: 'LBL0001178',
              align: 'center',
              sortable: false,
              child: [
                {
                  name: 'actionCnt',
                  field: 'actionCnt',
                  // 진행중인 건 수
                  label: 'LBL0001981',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                  style: 'width:10%',
                },
                {
                  name: 'completeCnt',
                  field: 'completeCnt',
                  // 완료 된 건 수
                  label: 'LBL0001981',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                  style: 'width:10%',
                },
                {
                  name: 'totalCnt',
                  field: 'totalCnt',
                  // 총 건 수
                  label: 'LBL0001981',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                  style: 'width:10%',
                },
              ]
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 등록일
              label: 'LBL0001979',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000020': // 안전작업 허가서
          /**
           * 분류 : 허가서 종류
           *  ex) 일반, 화기
           * 제목 : 작업개요
           * 진행상태 : 작업허가서 진행상태명
           * 작업 : 작업허가서 작업명
           * 보충작업 : 작업허가서 보충작업(,구분자)
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              // 분류
              label: 'LBLCLASSIFICATION',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              // 제목
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:30%',
              sortable: false,
              type: 'link',
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              // 진행상태
              label: 'LBLPROGRESS',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'sopName',
              field: 'sopName',
              // 작업
              label: 'LBLJOB',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'supplementWorkTypeNames',
              field: 'supplementWorkTypeNames',
              // 보충작업
              label: 'LBL0000205',
              align: 'left',
              style: 'width:20%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 등록일
              label: 'LBL0001979',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000025': // 기타
          columns = [
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:50%',
              type: 'text',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 등록일
              label: 'LBL0001979',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'attach',
              field: 'attach',
              // 첨부파일
              label: 'LBLATTACH',
              align: 'center',
              style: 'width:20%',
              type: 'attach',
              taskClassCd: 'MOC_ACTION_ETC',
              keyText: 'sopChangeRelatedWorkDocumentId',
              sortable: false,
            },
          ];
          break;
        default:
          columns = [
            {
              name: 'documentTitle',
              field: 'documentTitle',
              // 제목
              label: 'LBLTITLE',
              align: 'left',
              style: 'width:70%',
              type: 'text',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              // 등록자
              label: 'LBL0001978',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              // 등록일
              label: 'LBL0001979',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
      }
      this.$set(this.grid, 'columns', columns);
    },
    addDocument() {
      if (this.relatedWork.mocRelatedTaskCd !== 'RT00000025') {
        let _title = '';
        if (this.relatedWork.mocRelatedTaskCd == 'RT00000001') {  // 공정안전자료 업데이트
          _title = this.$comm.getLangLabel('LBL0001984') // 공정안전자료
        } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000005') {  // 위험성평가 결과
          _title = this.$comm.getLangLabel('LBL0001985') // 위험성평가
        } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000010') {  // 교육 결과
          _title = this.$comm.getLangLabel('LBL0001986') // 교육
        } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000015') {  // 가동전 점검 결과
          _title = this.$comm.getLangLabel('LBL0001987') // 가동전 점검
        } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000020') {  // 안전작업 허가서
          _title = this.$comm.getLangLabel('LBL0001988') // 안전작업'
        }
        this.popupOptions.title = this.$comm.getLangMessage('LBL0001989', {s1: _title}); // 관련 문서 (' + _title + ') 상세'
        this.popupOptions.param = {
          mocRelatedTaskCd: this.relatedWork.mocRelatedTaskCd,
          columns: this.grid.columns
        };
        this.popupOptions.target = () => import(`${'./taskRelationDocu.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else {
        // 기타
        this.relatedWork.relatedWorkDocuments.splice(0, 0, {
          sopChangeRelatedWorkDocumentId: uid(),  // 관련업무 일련번호
          sopChangeRelatedWorkId: this.relatedWork.sopChangeRelatedWorkId,  // 관련업무 일련번호
          documentId: '',  // 문서 일련번호
          documentTitle: '',  // 문서 제목
          documentTypeCd: 'DT00000050',  // 문서 타입
          documentRegDt: this.$comm.getTodayDateTime(),  // 문서 등록일
          documentRegUserName: this.$store.getters.user.userName,  // 문서 등록자
          documentRegUserId: this.$store.getters.user.userId,  // 문서 등록자 id
          editFlag: 'C'
        })
      }
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.relatedWork.relatedWorkDocuments) this.relatedWork.relatedWorkDocuments = [];
        this.$_.forEach(data, item => {
          this.relatedWork.relatedWorkDocuments.push({
            sopChangeRelatedWorkDocumentId: uid(),  // 관련업무 일련번호
            sopChangeRelatedWorkId: this.relatedWork.sopChangeRelatedWorkId,  // 관련업무 일련번호
            documentId: item.documentId,  // 문서 일련번호
            documentTitle: item.documentTitle,  // 문서 제목
            documentTypeCd: item.documentTypeCd,  // 문서 타입
            documentRegDt: item.documentRegDt,  // 문서 등록일
            documentRegUserName: item.documentRegUserName,  // 문서 등록자
            editFlag: 'C',

            classification: item.classification,  // 분류
            documentStepName: item.documentStepName,  // 진행상태
            sopName: item.sopName,  // 작업
            supplementWorkTypeNames: item.supplementWorkTypeNames,  // 보충작업
            actionCnt: item.actionCnt,  // 진행중인 건 수
            completeCnt: item.completeCnt,  // 완료 된 건 수
            totalCnt: item.totalCnt,  // 총 건 수
          })
        })
      }
    },
    removeDocument() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.relatedWork.deleteRelatedWorkDocuments) {
          this.relatedWork.deleteRelatedWorkDocuments = [];
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.relatedWork.deleteRelatedWorkDocuments, { sopChangeRelatedWorkDocumentId: item.sopChangeRelatedWorkDocumentId }) === -1
            && item.editFlag !== 'C') {
            this.relatedWork.deleteRelatedWorkDocuments.push(item)
          }
          this.relatedWork.relatedWorkDocuments = this.$_.reject(this.relatedWork.relatedWorkDocuments, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveRelatedWork() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.relatedWork.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRelatedWorkCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
      if (this.relatedWork.mocRelatedTaskCd === 'RT00000015') {
        this.$emit('changePreStartup')
      }
    },
    completeRelatedWork() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = 'MSGCOMPLETE'; // 완료하시겠습니까?
          if (!this.relatedWork.relatedWorkDocuments || this.relatedWork.relatedWorkDocuments.length === 0) {
            message = 'MSG0000511' // 등록 된 관련문서가 없습니다.\n\r완료하시겠습니까?
          }
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.relatedWork.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeRelatedWorkCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
      if (this.relatedWork.mocRelatedTaskCd === 'RT00000015') {
        this.$emit('changePreStartup')
      }
    },
    linkClick(row) {
      if (this.relatedWork.mocRelatedTaskCd == 'RT00000001') {  // 공정안전자료 업데이트
        if (row.documentTypeCd == 'DT00000010') {// 동력기계
          this.popupOptions.target = () => import(`${'@/pages/psi/pfi/powerMachineDetail.vue'}`);
          this.popupOptions.title = 'LBL0001990'; // 동력기계 상세
          this.popupOptions.param = {
            psiPowerEquipmentId: row ? row.documentId : '',
          };
        } else if (row.documentTypeCd == 'DT00000015') {// 장치 및 설비
          this.popupOptions.target = () => import(`${'@/pages/psi/pfi/deviceEquipmentDetail.vue'}`);
          this.popupOptions.title = 'LBL0001991'; // 장치 및 설비 상세
          this.popupOptions.param = {
            psiDeviceEquipmentId: row ? row.documentId : '',
          };
        } else if (row.documentTypeCd == 'DT00000020') {// 배관 및 개스킷
          this.popupOptions.target = () => import(`${'@/pages/psi/pfi/pipingGasketsDetail.vue'}`);
          this.popupOptions.title = 'LBL0001992'; // 배관 및 개스킷 상세
          this.popupOptions.param = {
            psiPipeGasketId: row ? row.documentId : '',
          };
        } else if (row.documentTypeCd == 'DT00000025') {// 안전밸브 및 파열판
          this.popupOptions.target = () => import(`${'@/pages/psi/pfi/safetyValveRuptureDiskDetail.vue'}`);
          this.popupOptions.title = 'LBL0001993'; // 안전밸브 및 파열판 상세
          this.popupOptions.param = {
            psiValveRuptureId: row ? row.documentId : '',
          };
        } else if (row.documentTypeCd == 'DT00000001') {// 도면
          this.popupOptions.target = () => import(`${'@/pages/psi/pidMasterDetail.vue'}`);
          this.popupOptions.title = this.$comm.getLangLabel('LBL0001994', {s1:row.classification}) // row.classification ' 상세';
          this.popupOptions.param = {
            psiDiagramId: row ? row.documentId : null,
            diagramClassCd: row.diagramClassCd,
            title: row.documentTitle
          };
        } else if (row.documentTypeCd == 'DT00000005') {// 문서
          this.popupOptions.target = () => import(`${'@/pages/psi/documentMasterDetail.vue'}`);
          this.popupOptions.title = this.$comm.getLangLabel('LBL0001994', {s1:row.classification}) // row.classification ' 상세';
          this.popupOptions.param = {
            psiDocumentId: row ? row.documentId : null,
            diagramClassCd: row.diagramClassCd,
            title: row.documentTitle
          };
        }
        this.popupOptions.width = '80%';
        this.popupOptions.isFull = false;
      } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000005') {  // 위험성평가 결과
        this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
        this.popupOptions.title = 'LBL0001995'; // 위험성평가 상세
        this.popupOptions.param = {
          ramRiskAssessmentPlanId: row ? row.documentId : '',
          ramStepCd: row ? row.ramStepCd : 'RRS0000001',
          vendorFlag: row ? (row.vendorFlag > 0) : false,
          ramTechniqueCd: row ? row.ramTechniqueCd : '',
        };
        this.popupOptions.width = '95%';
        // if (row.classificationCd == 'RT00000001') { // HAZOP
        //   this.popupOptions.target = () => import(`${'@/pages/ram/hazop/hazopDetail.vue'}`);
        //   this.popupOptions.title = 'HAZOP 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //   };
        // } else if (row.classificationCd == 'RT00000005') { // K-PSR
        //   this.popupOptions.target = () => import(`${'@/pages/ram/kpsr/kpsrDetail.vue'}`);
        //   this.popupOptions.title = 'K-PSR 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //   };
        // } else if (row.classificationCd == 'RT00000010') { // Check-list
        //   this.popupOptions.target = () => import(`${'@/pages/ram/checklist/checkListDetail.vue'}`);
        //   this.popupOptions.title = 'CHECKLIST 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //   };
        // } else if (row.classificationCd == 'RT00000015') { // JRA
        //   this.popupOptions.target = () => import(`${'@/pages/ram/jsa/jsaDetail.vue'}`);
        //   this.popupOptions.title = 'JSA 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //   };
        // } else if (row.classificationCd == 'RT00000020') { // KRAS
        //   this.popupOptions.target = () => import(`${'@/pages/ram/kras/krasDetail.vue'}`);
        //   this.popupOptions.title = 'KRAS 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //   };
        // } else if (row.classificationCd == 'RT00000025') { // 4M
        //   this.popupOptions.target = () => import(`${'@/pages/ram/4m/4mDetail.vue'}`);
        //   this.popupOptions.title = '4M 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //     vendorFlag: false,
        //   };
        // } else if (row.classificationCd == 'RT00000030') { // CHARM
        //   this.popupOptions.target = () => import(`${'@/pages/ram/charm/charmDetail.vue'}`);
        //   this.popupOptions.title = 'CHARM 위험성평가 상세';
        //   this.popupOptions.param = {
        //     ramRiskAssessmentPlanId: row ? row.documentId : '',
        //     ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        //   };
        // }
        // this.popupOptions.width = '95%';
        // this.popupOptions.isFull = false;
      } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000010') {  // 교육 결과
        this.popupOptions.target = () => import(`${'@/pages/sop/edu/result/educationResultDetail.vue'}`);
        this.popupOptions.title = "LBL0001996"; // 교육계획 및 결과 상세
        this.popupOptions.param = {
          eduEducationId: row ? row.documentId : '',
          stepCd: row.ramStepCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
        };
        this.popupOptions.width = '95%';
        this.popupOptions.isFull = false;
      } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000015') {  // 가동전 점검 결과
        this.popupOptions.target = () => import(`${'@/pages/sop/psr/preStartupReviewDetail.vue'}`);
        this.popupOptions.title = 'LBL0001997'; // 가동전점검 상세
        this.popupOptions.param = {
          sopPrestartupCheckId: row ? row.documentId : '',
        };
        this.popupOptions.width = '95%';
        this.popupOptions.isFull = false;
      } else if (this.relatedWork.mocRelatedTaskCd == 'RT00000020') {  // 안전작업 허가서
        this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
        this.popupOptions.title = 'LBL0001998'; // 안전작업 허가서 상세
        this.popupOptions.param = {
          sopWorkPermitId: row ? row.documentId : '',
          permitTypeCd: row ? row.ramStepCd : '',
        };
        this.popupOptions.width = '95%';
        this.popupOptions.isFull = false;
      }

      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupLink;
    },
    closePopupLink() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
